import * as React from "react";
import Header from "../components/Header/Header";
import Nav from "../components/Nav/Nav";
import { graphql, Link } from 'gatsby';
import get from 'lodash/get'

const isBrowser = typeof window !== "undefined";

export default class LibraryPage extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      libraryStories: [],
      isDesktop: false,
      asteriskPos: 0
    }
  }

  componentWillMount() {
    const libraryData = get(this, 'props.data.allContentfulStory.edges');
    this.setState({
      libraryStories: libraryData
    })
  }

  componentDidMount() {
    this.setDisplaySettings();
    window.addEventListener('resize', this.setDisplaySettings);
    this.setState({loading: false})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setDisplaySettings);
  }

  setDisplaySettings = () => {
    if (isBrowser) {
      this.setState({
        isDesktop: window.innerWidth > 1200
      })
    }
  }

  animateAsterisk = (index) => {
    this.setState({
      asteriskPos: index * 127
    })
  }

  render () {
    return (
      <>
      <Header/>
      <div className="wrapper">
       <Nav />
       <div className="library-story-list">
       <div className="library-asterisk" style={{transform: `translateY(${this.state.asteriskPos}px)`}}><h1>◌</h1></div>
       {
         this.state.libraryStories.map( (element,i) => {
           return (
             <Link key={`librarystory-${i}`} to="/" state={{ storyIndexFromLibrary: i+1, navColour: element.node.textColour ? "#000" : "#fff" }} onMouseEnter={this.state.isDesktop ? () => this.animateAsterisk(i) : null}>
             <div className="library-story" style={{backgroundColor: element.node.colour, color: element.node.textColour ? "#000" : "#fff"}} >
               <h1 className="left-side">Story {i+1}</h1>
               <p className="right-side">{element.node.prompt.internal.content}</p>
             </div>
             </Link>
           );
          })
        }
       </div>
     </div>
    </>
    )
  }
}

export const libraryQuery = graphql`
  {
    allContentfulStory (sort: {fields: [createdAt], order: ASC}) {
      edges {
        node {
          id
          prompt {
            internal {
              content
            }
          }
          colour,
          textColour
        }
      }
    }
  }
`;